import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "id": "toScroll"
    }
  }, [_c('SiteHeaderComponent', {
    attrs: {
      "header": _vm.address.name
    }
  }), _c('div', {
    staticClass: "d-flex justify-end"
  }), _c('div', [_c('TimeFilter', {
    ref: "timeFilter"
  })], 1), _c(VBottomNavigation, {
    staticClass: "ml-10 pb-2 pt-1",
    attrs: {
      "fixed": "",
      "horizontal": "",
      "scroll-threshold": "500",
      "hide-on-scroll": ""
    }
  }, [_c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('span', [_c('b', [_vm._v(_vm._s(_vm.address.name))])]), _c('br'), _c('span', [_c('b', [_vm._v(_vm._s(_vm.$store.state.statisticsTimeFilter.fromDate))]), _vm._v(" bis "), _c('b', [_vm._v(_vm._s(_vm.$store.state.statisticsTimeFilter.toDate))])])])]), _c(VDivider), _c('div', {
    attrs: {
      "id": "scroll-threshold-example"
    }
  }, [_c('div', {
    staticClass: "mt-4",
    attrs: {
      "id": "chart"
    }
  }, [_c('div', {
    staticClass: "mx-auto mb-12"
  }, [_c(VSwitch, {
    staticClass: "ml-4",
    attrs: {
      "label": "Werte-/Mengendarstellung"
    },
    model: {
      value: _vm.showAmountLocal,
      callback: function ($$v) {
        _vm.showAmountLocal = $$v;
      },
      expression: "showAmountLocal"
    }
  }), _c(VDataTable, {
    attrs: {
      "headers": _vm.headersSummary,
      "items": _vm.showAmount === true ? _vm.dataSummary : _vm.dataSummaryValues,
      "items-per-page": 5,
      "search": _vm.search,
      "loading": _vm.loading,
      "sort-by": "description",
      "item-key": "code",
      "sort-desc": _vm.sortDesc,
      "mobile-breakpoint": "800"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headersSummary.filter(header => header.value !== 'description' && header.value !== 'none'), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function ({
          value
        }) {
          return [_c('span', {
            key: header.text
          }, [_vm._v(_vm._s(_vm.formatArticleSummaryTableValue(value)))])];
        }
      };
    })], null, true)
  })], 1)]), _c('div', {
    staticClass: "mx-auto mb-12"
  }, [_c(VSwitch, {
    staticClass: "ml-4",
    attrs: {
      "label": "Werte-/Mengendarstellung"
    },
    model: {
      value: _vm.showAmountLocal,
      callback: function ($$v) {
        _vm.showAmountLocal = $$v;
      },
      expression: "showAmountLocal"
    }
  }), _c(VDataTable, {
    staticClass: "datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.showAmount === true ? _vm.articleTypesAmounts : _vm.articleTypesValues,
      "items-per-page": 5,
      "search": _vm.search,
      "item-key": "code",
      "loading": _vm.loading,
      "sort-by": "description",
      "sort-desc": _vm.sortDesc,
      "mobile-breakpoint": "800",
      "item-class": _vm.itemRowBackground2
    },
    on: {
      "click:row": _vm.openArticleType
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VTextField, {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Artikeltypen in diesem Zeitraum vorhanden. ")];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(header => header.value !== 'description'), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function ({
          value
        }) {
          return [_c('span', {
            key: header.text
          }, [_vm._v(_vm._s(_vm.formatArticleTypesTableValue(value)))])];
        }
      };
    })], null, true)
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.articleCategorysShow,
      expression: "articleCategorysShow"
    }]
  }, [_c('ArticleCategories', {
    attrs: {
      "lfdnr": this.lfdnr,
      "articleType": this.articleType
    }
  }), _c('router-view')], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };