import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "grey--text text--darken-2 mb-5"
  }, [_c('h3', [_vm._v("Typ -> Kategorie -> Artikel")]), _c('h2', [_vm._v(_vm._s(_vm.artNr) + " " + _vm._s(_vm.description))])]), _vm.detailsLoaded ? _c('div', {
    staticClass: "mx-auto mb-12"
  }, [_c(VSwitch, {
    staticClass: "ml-4",
    attrs: {
      "label": "Werte-/Mengendarstellung"
    },
    model: {
      value: _vm.showAmountLocal,
      callback: function ($$v) {
        _vm.showAmountLocal = $$v;
      },
      expression: "showAmountLocal"
    }
  }), _c('div', {
    staticClass: "grey--text text--darken-2 mb-5"
  }, [_c('h3', {
    staticClass: "ml-4 mb-n3"
  }, [_vm._v("Insgesamt")]), _c(VDataTable, {
    attrs: {
      "headers": _vm.headers3,
      "search": _vm.search,
      "mobile-breakpoint": "800",
      "items": _vm.showAmount === true ? _vm.itemsCompleteAmount : _vm.itemsCompleteValue,
      "dense": ""
    }
  })], 1), !_vm.showAmount ? _vm._l(_vm.newDataValue, function (data) {
    return _c('div', {
      key: data.sm2
    }, [data.sm2 !== null && data.sm2 !== 'AUTO' ? _c('div', {
      staticClass: "grey--text text--darken-2 mb-5"
    }, [!data.isEmpty ? [_c('h3', {
      staticClass: "ml-4 mb-n3"
    }, [_vm._v(_vm._s(data.sm2))]), _c(VDataTable, {
      attrs: {
        "headers": _vm.headers3,
        "items": data.data,
        "search": _vm.search,
        "mobile-breakpoint": "800",
        "dense": ""
      }
    })] : _vm._e()], 2) : _vm._e()]);
  }) : _vm._l(_vm.newDataAmount, function (data) {
    return _c('div', {
      key: data.sm2
    }, [data.sm2 !== null && data.sm2 !== 'AUTO' ? _c('div', {
      staticClass: "grey--text text--darken-2 mb-5"
    }, [!data.isEmpty ? [_c('h3', {
      staticClass: "ml-4 mb-n3"
    }, [_vm._v(_vm._s(data.sm2))]), _c(VDataTable, {
      attrs: {
        "headers": _vm.headers3,
        "items": data.data,
        "search": _vm.search,
        "mobile-breakpoint": "800",
        "dense": ""
      }
    })] : _vm._e()], 2) : _vm._e()]);
  })], 2) : _c('div', {
    staticClass: "mx-auto mb-12"
  }, [_c('div', {
    staticClass: "grey--text text--darken-2 mb-5"
  }, [_c('h3', {
    staticClass: "ml-4 mb-n3"
  }, [_vm._v("Details werden geladen...")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };