import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import addressService from '@/services/crm/addressService.js';
import distributorService from '@/services/statistics/distributorService.js';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import ArticleCategories from '@/components/statistics/EkRevenueStatistics/ArticleCategories.vue';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
export default {
  components: {
    SiteHeaderComponent,
    TimeFilter,
    ArticleCategories
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    summaryChartDataValues: [],
    summaryChartDataAmounts: [],
    summaryChartOptions: {},
    articleTypesAmounts: [],
    articleTypesValues: [],
    articleType: '0',
    headersSummary: [{
      text: 'Gesamt',
      value: 'none',
      align: 'left'
    }, {
      text: 'Anfangsbestand',
      value: 'opening',
      align: 'center'
    }, {
      text: 'Einkauf',
      value: 'purchases',
      align: 'center'
    }, {
      text: 'Verkauf',
      value: 'sales',
      align: 'center'
    }, {
      text: 'Inventur',
      value: 'inventory',
      align: 'center'
    }, {
      text: 'Materialverbrauch',
      value: 'materialsInput',
      align: 'center'
    }, {
      text: 'Endbestand',
      value: 'final',
      align: 'center'
    }],
    dataSummary: [],
    dataSummaryValues: [],
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    articleCodeTemp: '',
    articleCategorysShow: false,
    articlesShow: false,
    headers: [{
      text: 'Artikeltype',
      value: 'description',
      align: 'start'
    }, {
      text: 'Anfangsbestand',
      value: 'opening',
      align: 'end'
    }, {
      text: 'Einkäufe',
      value: 'purchases',
      align: 'end'
    }, {
      text: 'Verkäufe',
      value: 'sales',
      align: 'end'
    }, {
      text: 'Inventur',
      value: 'inventory',
      align: 'end'
    }, {
      text: 'Materialverbrauch',
      value: 'materialsInput',
      align: 'end'
    }, {
      text: 'Endbestand',
      value: 'finalAmount',
      align: 'end'
    }],
    // Chart
    series: [{
      name: 'Betrag',
      data: []
    }]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          },
          formatter: value => {
            return this.formatSummaryChartLabel(value);
          }
        },
        xaxis: {
          categories: [''],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            formatter: value => {
              return this.formatSummaryChartLabel(value);
            }
          }
        },
        title: {
          text: 'Gesamtstatistik',
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        colors: ['#FF6633']
      };
    },
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data;
      });
      this.loadArticleTypeStatistics();
      document.getElementById('toScroll').scrollIntoView({
        behavior: 'smooth'
      });
      this.summaryChartOptions = this.chartOptions();
      this.openArticleType(this.item);
    },
    loadArticleTypeStatistics() {
      this.loading = true;
      distributorService.getArticleTypesStatistics(this.lfdnr, this.filterFromDate, this.filterToDate).then(response => {
        // Filter out zero-lines
        const filteredArticleTypeValues = [];
        const filteredArticleTypeAmounts = [];
        response.data.forEach(articleType => {
          if (false === this.isZeroLine(articleType.valueModel)) {
            articleType.valueModel['description'] = articleType.description;
            articleType.valueModel['code'] = articleType.code;
            filteredArticleTypeValues.push(articleType.valueModel);
          }
          if (false === this.isZeroLine(articleType.amountModel)) {
            articleType.amountModel['description'] = articleType.description;
            articleType.amountModel['code'] = articleType.code;
            filteredArticleTypeAmounts.push(articleType.amountModel);
          }
        });
        this.articleTypesValues = filteredArticleTypeValues;
        this.articleTypesAmounts = filteredArticleTypeAmounts;
        this.loading = false;
      });
      distributorService.getArticleTypesSummaryStatistics(this.lfdnr, this.filterFromDate, this.filterToDate).then(response => {
        var obj = response.data;
        this.dataSummary = [];
        this.dataSummaryValues = [];
        this.dataSummary.push({
          opening: obj.openingAmount,
          purchases: obj.purchasesAmount,
          sales: obj.salesAmount,
          inventory: obj.inventoryAmount,
          materialsInput: obj.materialsInputAmount,
          final: obj.finalAmount
        });
        this.dataSummaryValues.push({
          opening: obj.openingValue,
          purchases: obj.purchasesValue,
          sales: obj.salesValue,
          inventory: obj.inventoryValue,
          materialsInput: obj.materialsInputValue,
          final: obj.finalValue
        });
      });
    },
    openArticleType(item) {
      if (this.articleCodeTemp == item.code) {
        this.articleCodeTemp = '';
        this.articleCategorysShow = false;
        this.articlesShow = false;
      } else {
        this.articleCodeTemp = item.code;
        this.articleType = item.code;
        //console.log(this.articleType)
        this.articleCategorysShow = true;
      }
      // statisticsDistributorArticleCategories
      /*this.$router.push({
        name: 'statisticsDistributorArticleCategories',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: { lfdnr: this.lfdnr, articleType: item.code }
      })*/
    },
    itemRowBackground2: function (item) {
      console.log('code: ' + item.code + 'artikelcodetemp: ' + this.articleType);
      if (item.code == this.articleCodeTemp) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    },
    formatArticleTypesTableValue(value) {
      if (this.showAmount === true) return value == 0 ? '' : value + '';else return value == 0 ? '' : currencyFormatService.formatEuro(value);
    },
    formatArticleSummaryTableValue(value) {
      if (this.showAmount) {
        return value == 0 ? '' : value + '';
      } else return value == 0 ? '' : currencyFormatService.formatEuro(value);
    },
    isZeroLine(model) {
      return model.opening === 0 && model.purchases === 0 && model.sales === 0 && model.inventory === 0 && model.materialsInput === 0 && model.finalAmount === 0;
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showAmount: state => state.distributors.showAmount
    }),
    showAmountLocal: {
      get() {
        return this.showAmount;
      },
      set(value) {
        this.$store.dispatch('setShowAmount', value);
      }
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    lfdnr() {
      this.init();
    },
    filterFromDate: function () {
      this.loadArticleTypeStatistics();
    },
    filterToDate: function () {
      this.loadArticleTypeStatistics();
    }
  }
};