import axios from 'axios'

export default {
  getRevenueLastThreeYears(branch) {
    return axios.get(`/v1/statistics/revenue/yearly/${branch}`)
  },
  getRevenueLastSixMonths(branch) {
    return axios.get(`/v1/statistics/revenue/monthly/${branch}`)
  },
  getMonthlyRevenueComparisonModel(branch) {
    return axios.get(`/v1/statistics/revenue/monthly/comparison/${branch}`)
  },

  getYearlyRevenueListCurrentMonth(branch) {
    return axios.get(`/v1/statistics/revenue/yearly/currentmonth/${branch}`)
  },

  getCustomerRevenue(ladr, fromMonth, toMonth) {
    return axios.get(`/v1/statistics/customers/${ladr}/revenue`, {
      params: { from: fromMonth, to: toMonth }
    })
  },

  getCustomerArticleTypes(ladr, fromMonth, toMonth) {
    return axios.get(`/v1/statistics/customers/${ladr}/articletypes`, {
      params: { from: fromMonth, to: toMonth }
    })
  },

  getCustomerRevenueLineChart(ladr, fromMonth, toMonth) {
    return axios.get(`/v1/statistics/customers/${ladr}/revenuechart`, {
      params: { from: fromMonth, to: toMonth }
    })
  },

  getCustomerArticleTypes2(ladr, type, fromMonth, toMonth) {
    return axios.get(
      `/v1/statistics/customers/${ladr}/articletypes/${type}/categories`,
      {
        params: { from: fromMonth, to: toMonth }
      }
    )
  },

  getArticleCategorys(ladr, category, fromMonth, toMonth) {
    return axios.get(`/v1/statistics/customers/${ladr}/articles/${category}`, {
      params: { from: fromMonth, to: toMonth }
    })
  },

  getCustomerArticleCategories(ladr, type, fromMonth, toMonth) {
    return axios.get(
      `/v1/statistics/customers/${ladr}/articlecategories/${type}`,
      {
        params: { from: fromMonth, to: toMonth }
      }
    )
  },

  getCustomerArticles(ladr, category, fromMonth, toMonth) {
    return axios.get(
      `/v1/statistics/customers/${ladr}/categories/${category}/articles`,
      {
        params: { from: fromMonth, to: toMonth }
      }
    )
  },

  getArticleDetails(ladr, art_nr, fromMonth, toMonth) {
    return axios.get(
      `/v1/statistics/customers/${ladr}/articledetails/${art_nr}`,
      {
        params: { from: fromMonth, to: toMonth }
      }
    )
  },

  getAllBranches() {
    return axios.get(`/v1/statistics/revenue/branches`)
  },

  getKKRevenue(ladr, from, to) {
    return axios.get(`/v1/statistics/healthinsurance/${ladr}/revenue`, {
      params: { from: from, to: to }
    })
  },

  getCustomerCounts(branch, from, to) {
    return axios.get(`/v1/statistics/revenue/customers/${branch}`, {
      params: { from: from, to: to }
    })
  },

  getKKRevenueMonthly(ladr, from, to) {
    return axios.get(`/v1/statistics/healthinsurance/${ladr}/monthly`, {
      params: { from: from, to: to }
    })
  },

  getWeeklyRevenue(branch, from) {
    return axios.get(`/v1/statistics/revenue/weekly/${branch}`, {
      params: { from: from }
    })
  },

  getRevenueOfWeek(branch, year, week) {
    return axios.get(`/v1/statistics/revenue/week/${branch}`, {
      params: { year: year, week: week }
    })
  },

  getYearlyRevenueList(ladr, from, to) {
    // return axios.get(`/v1/statistics/doctor/${ladr}/monthly`, {
    return axios.get(`/v1/statistics/doctor/${ladr}/monthlyWithCostEstimates`, {
      params: { from: from, to: to }
    })
  },

  getRevenueList(ladr, from, to) {
    return axios.get(`/v1/statistics/doctor/${ladr}/revenue`, {
      params: { from: from, to: to }
    })
  },

  getArticleTypesList(ladr, from, to) {
    return axios.get(`/v1/statistics/doctor/${ladr}/articletypes`, {
      params: { from: from, to: to }
    })
  },

  getArticleCategoriesList(ladr, type, from, to) {
    return axios.get(
      `/v1/statistics/doctor/${ladr}/articlecategories/${type}`,
      {
        params: { from: from, to: to }
      }
    )
  },

  getArticlesList(ladr, category, from, to) {
    return axios.get(`/v1/statistics/doctor/${ladr}/articles/${category}`, {
      params: { from: from, to: to }
    })
  },

  getArticleDetailsList(ladr, art_nr, from, to) {
    return axios.get(`/v1/statistics/doctor/${ladr}/articledetails/${art_nr}`, {
      params: { from: from, to: to }
    })
  }
}
