import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';

// Services
import addressService from '@/services/crm/addressService.js';
import distributorService from '@/services/statistics/distributorService.js';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import Articles from '@/components/statistics/EkRevenueStatistics/Articles.vue';

// Components

export default {
  components: {
    Articles
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    resultTypeName: {},
    resultValues: [],
    resultAmounts: [],
    articleNrTemp: '',
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    category: 0,
    headers: [{
      text: 'Artikelkategorie',
      align: 'start',
      value: 'description'
    }, {
      text: 'Anfangsbestand',
      value: 'opening',
      align: 'end'
    }, {
      text: 'Einkäufe',
      value: 'purchases',
      align: 'end'
    }, {
      text: 'Verkäufe',
      value: 'sales',
      align: 'end'
    }, {
      text: 'Inventur',
      value: 'inventory',
      align: 'end'
    }, {
      text: 'Materialverbrauch',
      value: 'materialsInput',
      align: 'end'
    }, {
      text: 'Endbestand',
      value: 'finalAmount',
      align: 'end'
    }]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data;
      });
      this.loadArticleCategoriesStatistics();
    },
    loadArticleCategoriesStatistics() {
      this.loading = true;
      distributorService.getArticleCategoriesStatistics(this.lfdnr, this.articleType, this.filterFromDate, this.filterToDate).then(response => {
        // Filter out zero-lines
        const categoriesValuesFiltered = [];
        const categoriesAmountsFiltered = [];
        response.data.categories.forEach(category => {
          if (false == (category.openingValue === 0 && category.purchasesValue === 0 && category.salesValue === 0 && category.inventoryValue === 0 && category.materialsInputValue === 0 && category.finalValue === 0)) categoriesValuesFiltered.push({
            nr: category.nr,
            description: category.description,
            opening: category.openingValue,
            purchases: category.purchasesValue,
            sales: category.salesValue,
            inventory: category.inventoryValue,
            materialsInput: category.materialsInputValue,
            finalAmount: category.finalValue
          });
          if (false == (category.openingAmount === 0 && category.purchasesAmount === 0 && category.salesAmount === 0 && category.inventoryAmount === 0 && category.materialsInputAmount === 0 && category.finalAmount === 0)) categoriesAmountsFiltered.push({
            nr: category.nr,
            description: category.description,
            opening: category.openingAmount,
            purchases: category.purchasesAmount,
            sales: category.salesAmount,
            inventory: category.inventoryAmount,
            materialsInput: category.materialsInputAmount,
            finalAmount: category.finalAmount
          });
        });
        this.resultTypeName = response.data.typeName;
        this.resultValues = categoriesValuesFiltered;
        this.resultAmounts = categoriesAmountsFiltered;
        this.loading = false;
      });
    },
    itemRowBackground3: function (item) {
      if (item.nr == this.articleNrTemp) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    },
    openArticleCategory(item) {
      console.log(item.nr);
      if (this.articleNrTemp == item.nr) {
        this.articleNrTemp = '';
        // TODO: FIX MUTATING PROPS
        // eslint-disable-next-line
        this.articlesShow = false;
      } else {
        this.articleNrTemp = item.nr;
        this.category = item.nr;
        // eslint-disable-next-line
        this.articlesShow = true;
      }

      // statisticsDistributorArticleCategories
      /*this.$router.push({
        name: 'statisticsDistributorArticles',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: {
          lfdnr: this.lfdnr,
          articleType: this.articleType,
          category: item.nr
        }
      })*/
    },
    formatArticleTypesTableValue(value) {
      if (this.showAmount === true) return value == 0 ? '' : value + '';else return value == 0 ? '' : currencyFormatService.formatEuro(value);
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showAmount: state => state.distributors.showAmount
    }),
    showAmountLocal: {
      get() {
        return this.showAmount;
      },
      set(value) {
        this.$store.dispatch('setShowAmount', value);
      }
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    filterFromDate: function () {
      this.loadArticleCategoriesStatistics();
    },
    filterToDate: function () {
      this.loadArticleCategoriesStatistics();
    },
    articleType: function () {
      this.init();
    }
  }
};