/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ArticleTypes.vue?vue&type=template&id=1dd7a786&scoped=true"
import script from "./ArticleTypes.vue?vue&type=script&lang=js"
export * from "./ArticleTypes.vue?vue&type=script&lang=js"
import style0 from "./ArticleTypes.vue?vue&type=style&index=0&id=1dd7a786&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dd7a786",
  null
  
)

export default component.exports