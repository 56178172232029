import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "grey--text text--darken-2 mb-5"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.resultTypeName) + " -> " + _vm._s(_vm.resultCategoryName) + " -> Artikel ")])]), _c('div', {
    staticClass: "mx-auto mb-12"
  }, [_c(VSwitch, {
    staticClass: "ml-4",
    attrs: {
      "label": "Werte-/Mengendarstellung"
    },
    model: {
      value: _vm.showAmountLocal,
      callback: function ($$v) {
        _vm.showAmountLocal = $$v;
      },
      expression: "showAmountLocal"
    }
  }), _c(VDataTable, {
    ref: "datatable",
    staticClass: "datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.showAmount === true ? _vm.filteredResultAmounts : _vm.filteredResultValues,
      "items-per-page": 5,
      "search": _vm.search,
      "item-key": "code",
      "loading": _vm.loading,
      "sort-by": "description",
      "sort-desc": _vm.sortDesc,
      "mobile-breakpoint": "800",
      "item-class": _vm.itemRowBackground4
    },
    on: {
      "click:row": _vm.openArticle,
      "current-items": _vm.test
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VTextField, {
          staticClass: "mb-2",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Suche",
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c(VTooltip, {
          attrs: {
            "top": "",
            "color": "red"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c(VBtn, _vm._g(_vm._b({
                staticClass: "white--text ml-5",
                staticStyle: {
                  "float": "right"
                },
                attrs: {
                  "color": "red"
                },
                on: {
                  "click": function ($event) {
                    return _vm.resetFilter();
                  }
                }
              }, 'v-btn', attrs, false), on), [_c(VIcon, [_vm._v(" mdi-close-thick ")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("Filter löschen")])]), _c(VAutocomplete, {
          attrs: {
            "outlined": "",
            "dense": "",
            "label": "Artikelgruppe",
            "items": _vm.articleGroups
          },
          on: {
            "change": _vm.filterArticleGroup
          },
          model: {
            value: _vm.selectedArticleGroup,
            callback: function ($$v) {
              _vm.selectedArticleGroup = $$v;
            },
            expression: "selectedArticleGroup"
          }
        })];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Artikel in diesem Zeitraum vorhanden. ")];
      },
      proxy: true
    }, {
      key: "item.images",
      fn: function ({
        item
      }) {
        return [item.images[0] !== undefined ? _c(VImg, {
          staticClass: "ma-1",
          attrs: {
            "height": "50px",
            "width": "50px",
            "contain": "",
            "src": item.images[0].image
          },
          on: {
            "click": function ($event) {
              return _vm.openImage(item.images);
            }
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.opening",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatEuroOrZero(item, 'opening')) + " ")];
      }
    }, {
      key: "item.purchases",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatEuroOrZero(item, 'purchases')) + " ")];
      }
    }, {
      key: "item.sales",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatEuroOrZero(item, 'sales')) + " ")];
      }
    }, {
      key: "item.inventory",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatEuroOrZero(item, 'inventory')) + " ")];
      }
    }, {
      key: "item.materialsInput",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatEuroOrZero(item, 'materialsInput')) + " ")];
      }
    }, {
      key: "item.finalAmount",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.formatEuroOrZero(item, 'finalAmount')) + " ")];
      }
    }])
  }, [_c('template', {
    slot: "body.append"
  }, [_c('tr', [_c('td', {
    staticClass: "font-weight-bold",
    attrs: {
      "align": "left"
    }
  }, [_vm._v("Gesamt")]), _c('td', {
    staticClass: "font-weight-bold"
  }), _c('td', {
    staticClass: "font-weight-bold"
  }), _c('td', {
    staticClass: "font-weight-bold"
  }), _c('td', {
    staticClass: "font-weight-bold",
    attrs: {
      "align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatEuroOrZeroSum(_vm.sum[0].opening)) + " ")]), _c('td', {
    staticClass: "font-weight-bold",
    attrs: {
      "align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatEuroOrZeroSum(_vm.sum[0].purchases)) + " ")]), _c('td', {
    staticClass: "font-weight-bold",
    attrs: {
      "align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatEuroOrZeroSum(_vm.sum[0].sales)) + " ")]), _c('td', {
    staticClass: "font-weight-bold",
    attrs: {
      "align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatEuroOrZeroSum(_vm.sum[0].inventory)) + " ")]), _c('td', {
    staticClass: "font-weight-bold",
    attrs: {
      "align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatEuroOrZeroSum(_vm.sum[0].materialsInput)) + " ")]), _c('td', {
    staticClass: "font-weight-bold",
    attrs: {
      "align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatEuroOrZeroSum(_vm.sum[0].finalAmount)) + " ")])])])], 2)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.articlesDetailedShow,
      expression: "articlesDetailedShow"
    }]
  }, [this.artNr != '0' ? _c('ArticlesDetailed', {
    attrs: {
      "lfdnr": this.lfdnr,
      "artNr": this.artNr,
      "description": this.description
    }
  }) : _vm._e(), _c('router-view')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };